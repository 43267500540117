export default {
  data() {
    return {
      formFlag: true,
      hideUpload: true,
      resultFlag: false,
      successFlag: false,
      icon_show_success: false,
      icon_show_fail: false,
      resultMessage: '',
      userId: '',
      paymentDepositId: '',
    }
  },
}
