<template>
  <div class="success">
    <div class="success-content">
      <slot name="img"></slot>
      <template v-if="!selfImg">
        <img v-if="fail" src="@/assets/images/dialog_false.png" alt />
        <img v-else-if="info" src="@/assets/images/dialog_info.png" alt />
        <img v-else src="@/assets/images/dialog_true.png" alt />
      </template>

      <p class="success_info">
        <slot></slot>
      </p>
      <div class="btn-box">
        <router-link :to="{ name: backUrl ? backUrl : 'home' }" class="el-button btn-red" data-testid="bkToHm">
          {{ $t('common.button.bkToHm') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'result',
  props: {
    fail: Boolean,
    info: Boolean,
    backUrl: String,
    selfImg: Boolean
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/result.scss';
</style>
