<template>
  <div class="content-box">
    <div class="inner">
      <div class="cc-box">
        <div class="main" v-show="formFlag || successFlag">
          <div v-if="formFlag">
            <slot></slot>
          </div>
          <div class="credit-success" v-if="successFlag">
            <div class="form_success_content">
              <div v-if="hideUpload">
                <img v-if="icon_show_success" src="@/assets/images/dialog_true.png" alt />
                <img v-if="icon_show_fail" src="@/assets/images/dialog_false.png" alt />
                <p class="last">{{ resultMessage }}</p>
                <router-link :to="{ name: 'home' }" class="el-button btn-red">
                  {{ $t('common.button.bkToHm') }}
                </router-link>
              </div>
              <div v-else>
                <!-- only sdPay -->
                <el-form label-position="top" status-icon ref="paymentForm">
                  <p>{{ $t('deposit.cc.cardUpload') }}</p>
                  <ul class="clearfix creditOrDebitUpload">
                    <li>
                      <vUpload :limit="1" v-on:updateFileInfo="updateFileInfo"></vUpload>
                    </li>
                    <li>
                      <img src="@/assets/images/channel/master_img.png" alt />
                    </li>
                  </ul>
                  <el-button class="btn-red" @click="uploadImg()" data-testid="uploadImg">
                    {{ $t('common.button.submit') }}
                  </el-button>
                </el-form>
              </div>
            </div>
          </div>
        </div>

        <Result :fail="true" v-if="resultFlag">
          <div
            v-html="
              $t('deposit.cc.result.error', {
                email: GLOBAL_CONTACT_EMAIL
              })
            "
          ></div>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import vUpload from '@/components/vUpload';
import Result from '@/components/Result';
import { apiUpload_card } from '@/resource';

export default {
  props: {
    formFlag: Boolean,
    hideUpload: Boolean,
    resultFlag: Boolean,
    successFlag: Boolean,
    icon_show_success: Boolean,
    icon_show_fail: Boolean,
    resultMessage: String,
    userId: String,
    paymentDepositId: String
  },
  components: {
    vUpload,
    Result
  },
  data() {
    return {
      fileList: []
    };
  },
  methods: {
    updateFileInfo(fileInfo) {
      this.fileList = fileInfo.fileList;
    },
    uploadImg(fileInfo) {
      if (this.fileList.length == 0) {
        var vm = this;
        this.$message({
          message: vm.$t('common.formValidation.file'),
          type: 'error'
        });
        return;
      }
      this.UploadCard();
    },
    UploadCard() {
      apiUpload_card({
        paymentDepositId: this.paymentDepositId,
        userId: this.userId,
        fileList: this.fileList
      })
        .then(resp => {
          if (resp.data.data) {
            this.$emit('update:hideUpload', true);
            this.$emit('update:icon_show_success', true);
            this.$emit('update:resultMessage', this.$t('deposit.cc.result.success'));
          }
        })
        .catch(err => {
          this.$message({
            message: 'Upload failed. please try later.',
            type: 'error'
          });
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/template/deposit/creditCardTemplate.scss';
</style>
